var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { attrs: { cols: "12", sm: "10", md: "8", lg: "6", xl: "6" } },
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c(
            "v-card-title",
            [
              _c("v-row", { attrs: { align: "center", justify: "center" } }, [
                _c("h3", [
                  _vm._v(_vm._s(_vm.$t("views.constants.updatePassword")))
                ])
              ])
            ],
            1
          ),
          _c(
            "v-card-subtitle",
            [
              _c("v-row", { attrs: { align: "center", justify: "center" } }, [
                _c("h4", [_vm._v(_vm._s(_vm.$t("views.constants.toContinue")))])
              ])
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c("app-form", {
                attrs: {
                  fields: _vm.fields,
                  "submit-button": _vm.submitButton,
                  "disable-cancel-button": ""
                },
                on: { submitted: _vm.handleSubmit }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }